@import "main-bx";

.footer {
  background-color: $color_black;
  padding: 73px 0 120px;
  color: $color_white;
  &__wrap {
    display: flex;
    flex-direction: column;
  }
  &__logo {
    width: 12.9rem;
    margin-bottom: 3.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  &__center {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    @include below(1200px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
  &__left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.25rem;
    height: 1.25rem;
    @include below(1200px) {
      justify-content: center;
      height: auto;
    }
  }
  &__img {
    height: 100%;
  }
  &__right {
    display: flex;
    align-items: center;
    gap: 20px;
    @include below(1200px) {
      margin-bottom: 2rem;
    }
    &-time {
      &_small {
        text-align: right;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        text-transform: uppercase;
      }
      &_big {
        text-align: right;
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        text-transform: uppercase;
      }
    }
    &-social {
      display: flex;
      align-items: center;
      @include below(600px) {
        flex-direction: column;
      }
      a {
        &:not(:last-child) {
          margin-right: 12px;
          @include below(600px) {
            margin-right: 0;
            margin-bottom: 9px;
          }
        }
      }
    }
  }
  &__text {
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 3.75rem;
  }
  &__copyright {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }
}
