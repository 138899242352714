@import "main-bx";

.intro {
  width: auto;
  background: #fff;
  height: 320px;
  margin-bottom: 3.75rem;
  @include below(48em) {
    margin-bottom: 2.5rem;
  }
  @include below(363px) {
    margin-bottom: 4.5rem;
  }
  @include below(337px) {
    margin-bottom: 5.5rem;
  }
  &__img {
    &-desktop {
      height: 320px;
      position: relative;
    }
    img {
      position: relative;
      bottom: 0;
      object-position: top;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color_black;
    border-radius: 10px;
    padding: 26px 32px 30px;
    position: relative;
    z-index: 20;
    width: 100%;
    height: 210px;
    margin-top: -250px;
    margin-bottom: 365px;
    @include below(48em) {
      height: auto;
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
    @include below(48em) {
      flex-direction: column;
    }
    &_img {
      display: none;
      @include below(48em) {
        display: block;
        width: 62px;
        flex-shrink: 0;
        margin-left: 13px;
      }
      img {
        border-radius: 10px;
      }
    }
  }
  &__right {
    flex-shrink: 0;
    flex-basis: 10rem;
    @include below(48em) {
      display: none;
    }
    .intro__img {
      img {
        border-radius: 10px;
      }
    }
  }
  &__text {
    color: #8294A6;
    font-size: 0.9625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
    margin-bottom: 10px;
    &-block {
      @include below(48em) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  &__title {
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    color: $color_white;
    @include below(64em) {
      margin-bottom: 1.5rem;
    }
    @include below(48em) {
      font-size: 1.75rem;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include below(26.5em) {
      display: flex;
      flex-direction: column;
    }
  }
  &__btn {
    @include below(48em) {
      width: 48%;
    }
    @include below(26.5em) {
      width: 100%;
    }
    &:not(:last-child) {
      margin-right: 1.69rem;
      @include below(48em) {
        margin-right: 1rem;
      }
      @include below(26.5em) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
  &-2 {
    margin-bottom: 0 !important;
    height: 818px;
    position: relative;
    @include below(31em) {
      height: 500px;
    }
    .intro__img {
      height: 818px;
      position: relative;
      @include below(31em) {
        height: 500px;
      }
    }
    .intro__content {
      margin-top: -600px;
      flex-direction: column;
      height: auto;
      padding: 0;
      justify-content: center;
      align-items: center;
      background: none;
      max-width: 55rem;
      margin-left: auto;
      margin-right: auto;
      @include below(31em) {
        margin-top: -380px;
      }
    }
    .intro__title {
      font-size: 4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 4.6rem;
      text-align: center;
      @include below(768px) {
        font-size: 2.7rem;
        line-height: 120%;
      }
      @include below(31em) {
        font-size: 2rem;
      }
      span {
        font-size: 10.625rem;
        @include below(768px) {
          font-size: 8rem;
        }
        @include below(31em) {
          font-size: 4rem;
        }
      }
    }
    .intro__text {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: $color_white;
      text-align: center;
      text-transform: none;
      margin-bottom: 2rem;
      @include below(31em) {
        font-size: 1.125rem;
      }
    }
    .intro__btn {
      text-transform: uppercase;
      white-space: nowrap;
      font-size: 1.375rem;
      line-height: 120%;
      padding: 10px 16px;
    }
  }
}
