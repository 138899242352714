@import "main-bx";

.header {
  background-color: $color_black;
  @include below(55em) {
  }
  &__top {
    padding: .75rem 0 .69rem;
  }
  &__right {
    display: flex;
    align-items: center;
    gap: 14px;
  }
  &__text {
    &-block {
      display: flex;
      margin-right: 1.13rem;
      color: $color_yellow;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.125rem;
      @include below(43.75em) {
        flex-direction: column;
        font-size: .75rem;
        line-height: 170%;
        margin-right: .5rem;
      }
    }
  }
  &__link {
    text-decoration-line: underline;
    font-weight: 700;
    margin-left: .5rem;
    color: $color_yellow;
    @include below(43.75em) {
      margin-left: 0;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include below(31em) {
      flex-direction: column;
      justify-content: center;
    }
  }
  &__logo {
    width: 8.7rem;
    flex-shrink: 0;
    @include below(31em) {
      margin-bottom: 1rem;
    }
  }
}
