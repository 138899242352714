@import "main-bx";

.btn {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 5px 31px;
  font-family: $font_base;
  font-size: .875rem;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  white-space: nowrap;

  text-align: center;
  color: $color_white;
  background-color: transparent;
  border: 1px solid $color_white;
  cursor: pointer;
  @include below(43.75em) {
    padding: 5px 13px;
  }

  &-orange {
    padding: 5px 40px;
    border: 1px solid $color_orange;
    background-color: $color_orange;
    @include below(48em) {
      padding: 5px 14px;
    }
    &_2 {
      font-size: 1rem;
      font-weight: 400;
      padding: 8px 51px;
      text-transform: none;
      border-radius: 5px;
      @include below(48em) {
        width: 100%;
      }
    }
  }
  &-pink {
    font-size: 1rem;
    font-weight: 400;
    text-transform: none;
    padding: 8px 56px;
    background-color: $color_pink;
    border: 1px solid $color_pink;
    border-radius: 5px;
    @include below(48em) {
      width: 100%;
    }
  }
}
