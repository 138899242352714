@import "main-bx";
.mb {
  &-100 {
    margin-bottom: 100px;
  }
  &-50 {
    margin-bottom: 50px;
  }
  &-40 {
    margin-bottom: 40px;
  }
  &-20 {
    margin-bottom: 20px;
  }
}

@media (max-width: 48em) {
  .mb {
    &-50 {
      margin-bottom: 30px;
    }
    &-40 {
      margin-bottom: 30px;
    }
  }
}
