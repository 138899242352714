@import "main-bx";

h1 {
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  color: $color_white;
  @include below(64em) {
    margin-bottom: 1.5rem;
  }
  @include below(48em) {
    font-size: 1.75rem;
  }
}

h2 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: $color_pink;
  margin-bottom: 2.5rem;
  text-align: left;
  @include below(64em) {
    font-size: 1.75rem;
    margin-bottom: 1.87rem;
    text-align: center;
  }
}

.h2_center {
  text-align: center;
}

h3 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  margin-bottom: 1.25rem;
  color: $color_pink;
  @include below(48em) {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.text {
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem;
}

.text-table {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110.182%;
  color: #000;
  &-title {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 82.182%;
    color: #000;
    text-align: center;
    @include below(64rem) {
      font-size: 1.25rem;
      line-height: 104.7%;
    }
  }
}



