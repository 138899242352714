@import "main-bx";

.nota {
  background-color: $color_orange;
  padding: 1.94rem 0;
  margin-bottom: 2rem;
  .container {
    max-width: 958px;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }
  &__nota {
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: rgba(255, 255, 255, 0.50);
    transform: rotate(-90deg);
  }
  &__text {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: $color_white;
  }
}
