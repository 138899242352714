@import "main-bx";

.nav {
  background-color: $color_black;
  &__menu {
    position: relative;
    overflow: hidden;
    &-desktop {
      .nav__list {
        > li {
          margin-left: .78rem;
          &:not(:last-child) {
            margin-right: .78rem;
          }
        }
      }
      @include below(980px) {
        display: none;
      }
    }
    &-mobile {
      display: none;
      @include below(980px) {
        display: block;
        &.js-nav {
          width: 100%;
          z-index: 2;
          .swiper-wrapper {
            flex-wrap: nowrap;
            width: 100%;
            margin: 0 auto;
          }
          .swiper-slide {
            margin-left: 1rem;
            margin-right: 1rem;
            width: auto!important;
          }
          .swiper-button-next {
            fill: #fff;
            width: 20px;
            height: 20px;
            z-index: 30;
            top: 40px;
            right: -2px;
            background-color: #000;
            &::after {
              font-size: 0;
              @include below(61.25em) {
                content: "";
                position: absolute;
                top: -14px;
                right: 20px;
                background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
                transform: rotate(180deg);
                width: 150px;
                height: 51px;
                z-index: 20;
                overflow: visible;
              }
            }
            &.swiper-button-disabled {
              opacity: 0;
            }
          }
          .swiper-button-prev {
            fill: #fff;
            width: 20px;
            height: 20px;
            z-index: 30;
            top: 40px;
            left: -2px;
            background-color: #000;
            &::after {
              font-size: 0;
            }
            &.swiper-button-disabled {
              opacity: 0;
            }
          }
        }
      }
    }

  }
  &__list {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    > li {
      //&:not(:last-child) {
      //  margin-right: .78rem;
      //  margin-left: .78rem;
      //}
    }
  }
  &__link {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3.5625rem;
    text-transform: uppercase;
    color: $color_white;
    //white-space: nowrap;
    &_active {
      color: $color_orange;
    }
    &:hover {
      color: $color_orange;
    }
  }
}
